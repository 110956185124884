<template>
<div style='margin-bottom: 5rem;'>
  <van-row class='top'>
    <van-row class='top-left'>
      <van-image
        round
        width="6rem"
        height="6rem"
        :src="require('../../assets/img/btc.png')"
      />
    </van-row>
    <van-row class='top-right'>
      <van-row @click="tosetting" style='font-size: 1rem;height: 1.5rem;color: #fff;'>
        <b v-if="user.nickname">{{ user.nickname }}</b>
        <b v-else>{{ $t('home.nickname') }}</b>
      </van-row>
      <van-row style='font-size: 1rem;height: 1.5rem;color: #fff;'>
        <span>UID:{{ user.username }}</span>
      </van-row>
      <van-row style='font-size: 1rem;height: 1.5rem;'>
        <span style="font-size: 15px;font-weight: 500;color: #fff;">{{ $t('assets.credit') }}:<span style="font-size: 16px;font-weight: 800;">{{ score }}</span> </span>
      </van-row>
      <van-row>
        <span style="font-size: 16px;font-weight: 800;color: red;">{{ $t(level) }}</span>
      </van-row>
    </van-row>
  </van-row>
  <van-grid :column-num="1">
    <van-grid-item>
      <van-row>
        <p style='display: flex;margin-bottom: 0.3rem;font-size: 0.7rem;'>{{ $t('assets.zassets') }}({{ currency.toUpperCase() }})</p>
        <b>{{ balance.usd*currencyhuilv | priceformat}}</b>
      </van-row>
    </van-grid-item>
  </van-grid>
<!--  <van-grid :column-num="2">-->
<!--    <van-grid-item>-->
<!--      <van-row>-->
<!--        <p style='display: flex;margin-bottom: 0.3rem;font-size: 0.7rem;'>{{ $t('assets.tprofit') }}({{ currency.toUpperCase() }})</p>-->
<!--        <b>{{ tongji['profit']*currencyhuilv | priceformat}}</b>-->
<!--      </van-row>-->
<!--    </van-grid-item>-->
<!--    <van-grid-item>-->
<!--      <van-row>-->
<!--        <p style='display: flex;margin-bottom: 0.3rem;font-size: 0.7rem;'>{{ $t('assets.tamount') }}({{ currency.toUpperCase() }})</p>-->
<!--        <b>{{ tongji['price']*currencyhuilv | priceformat}}</b>-->
<!--      </van-row>-->
<!--    </van-grid-item>-->
<!--  </van-grid>-->
  <div>
    <van-cell-group style='width: 90%;margin: 0 auto;border-radius: 20px;background-color: #1a2735;'>
      <van-cell
        center
        icon="balance-pay"
        :title="$t('assets.charge')"
        is-link
        to="/assets/recharge"
      />
      <van-cell
        center
        icon="cash-back-record"
        :title="$t('assets.withdraw')"
        is-link
        to="/assets/withdraw"
      />
      <van-cell
        center
        icon="setting-o"
        :title="$t('home.set')"
        is-link
        to="/person/setting"
      />
      <van-cell
        center
        icon="exchange"
        :title="$t('assets.duihuan')"
        is-link
        to="/trade/shopping/trading"
      />
      <van-cell
        center
        icon="question-o"
        :title="$t('home.help')"
        is-link
        to="/person/setting"
      />
      <van-cell
        center
        icon="service-o"
        :title="$t('home.serverurl')"
        is-link
        :url="serverurl"
      />
      <van-cell
        center
        icon="replay"
        :title="$t('home.logout')"
        is-link
        @click="logout"
        style='border-radius: 0 0 15px 15px'
      />
    </van-cell-group>
  </div>
</div>
</template>

<script>
export default {
  name: 'Index',
  data() {
    return {
      selectLang: '',
      selectLangOption: [
        { text: '中文', value: 'zh' },
        { text: 'English', value: 'en' },
        { text: 'русский язык', value: 'ru' },
        { text: 'Français', value: 'fr' },
        { text: 'Español', value: 'es' },
        { text: 'Italiano', value: 'it' }
      ],
      lang: localStorage.getItem('lang') || 'zh',
      currency: localStorage.getItem('currency') || 'USDT',
      level: '',
      serverurl: '',
      user: {},
      editpass: {
        oldpass: '',
        newpass: '',
        newpass2: ''
      },
      score: 0,
      currencyhuilv: 1,
      userinfo: {},
      balance: {},
      tongji: [],
      msgcount: 0,
      notifyshow: false,
      websocket: null,
      wk: null,
      active: 0
    }
  },
  created() {
    this.getdata()
    this.getinfo()
  },
  methods: {
    // 获取首页数据
    async getdata() {
      const { data } = await this.$http.get('/home/home/index')
      if (data) {
        if (data.code === 200) {
          this.serverurl = data.data.serverurl
          this.user = data.data.user
          switch (data.data.level) {
            case '1':
              this.level = 'home.vip1'
              break
            case '2':
              this.level = 'home.vip2'
              break
            case '3':
              this.level = 'home.vip3'
              break
            case '4':
              this.level = 'home.vip4'
              break
            case '5':
              this.level = 'home.vip5'
              break
          }
          if (data.data.user.random_password === '1') {
            this.suiji = true
          }
        }
      }
    },
    async getinfo() {
      const { data } = await this.$http.get('/home/user/index')
      if (data) {
        if (data.code === 200) {
          let that = this
          this.score = +data.data.userinfo.credit_score
          this.userinfo = data.data.userinfo
          this.balance = data.data.balance
          this.tongji = data.data.tongji
          let units = data.data.unitinfo
          units.forEach(function(value, index, units) {
            if (units[index].unit == that.currency) {
              that.currencyhuilv = units[index].huilv
            }
          })
        }
      }
    },
    // 去设置页
    tosetting() {
      this.$router.push('/person/setting')
    },
    // 退出
    logout() {
      window.localStorage.removeItem('token')
      this.$router.push('/login')
    }
  }
}
</script>

<style lang="less" scoped>
/deep/ .van-hairline--top-bottom:after,
/deep/ .van-hairline-unset--top-bottom:after {
  border-width: 0;
}

/deep/ .van-hairline--top::after {
  border-top-width: 0px;
}


/deep/ .van-grid-item__content {
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;
  color: #fff;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  flex-direction: column;
  box-sizing: border-box;
  height: 78%;
  width: 90%;
  margin: 20px auto 0;
  padding: 16px 8px;
  background-color: #1a2735;
  border-radius: 15px 15px 0 0;
  //border-bottom: 1px solid #3b3b3b;
}
/deep/ .van-grid-item__content::after {
  z-index: 1;
  border: none;
}
.van-cell {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;
  box-sizing: border-box;
  width: 100%;
  padding: 10px 16px;
  overflow: hidden;
  color: #fff;
  font-size: 14px;
  line-height: 24px;
  background-color: #1a2735;
}
.van-cell::after {
  position: absolute;
  box-sizing: border-box;
  content: ' ';
  pointer-events: none;
  right: 16px;
  bottom: 0;
  left: 16px;
  border-bottom: 1px solid #3b3b3b;
  -webkit-transform: scaleY(.5);
  transform: scaleY(.5);
}
// 弹出层
.top {
  height: 18%;
  display: flex;
  align-items: center;
  //justify-content: center;
.top-left {
  margin-right: 2%;
  float: left;
  padding: 1rem;
}
.top-right {
  text-align: left;
  font-size: 1.33333rem;
  line-height: 2.13333rem;
}
}

.van-cell {
  height: 3.2rem;
  font-size: 0.93333rem;
}
.van-cell__title {
  text-align: left;
}
.van-cell__value {
  color: #000;
}
</style>
